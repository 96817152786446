.calendar-main {
  width: 110px;
  height: auto;
  background-color: #f6f5fb;
  border-radius: 10px;
  padding: 10px;
}

.calendar-main-full {
  width: 115px;
  height: auto;
  background-color: #f6f5fb;
  border-radius: 10px;
  padding: 10px;
}

.fc-h-event,
.fc-v-event {
  border: unset !important;
  border: unset !important;
  background-color: transparent !important;
  background-color: transparent !important;
}

.fc .fc-timegrid-slot {
  height: 140px !important;
}

.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent !important;
}

.time {
  color: #144067;
  font-family: inherit;
}

.title {
  color: #fe5722;
  font-family: inherit;
}

.card-main-button,
.card-main-button:hover {
  border-radius: 10px;
  text-transform: uppercase;
  width: max-content;
}

.enroll,
.enroll:hover
{
  background-color: #cbcfd7;
  color: #144067;
}

.enroll:disabled {
  background-color: #cbcfd7;
  color: #144067;
  pointer-events: none;
}

.fc-timegrid-col,
.fc-col-header-cell{
  width: 120px !important;
}
.fc .fc-scrollgrid-liquid {
  table-layout: auto !important;
}

.fc .fc-view-harness-active > .fc-view{
  overflow: scroll;
}

.calender_title{
  white-space: pre-wrap;
  word-break: break-word;
}

a.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-draggable.fc-event-resizable.fc-event-start.fc-event-end.fc-event-today,
a.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-draggable.fc-event-resizable.fc-event-start.fc-event-end.fc-event-future {
  width: 110px;
  height: auto;
  border-radius: 10px;
}

.cardactive{
 background-color: #fee9e3 !important
}

.cardactive .calender_title{
  color: #144067;
}

.fc-myCustomButton-button, .fc-myCustomButton-button:hover{
  --bs-btn-padding-x: 1.5rem  !important;
    --bs-btn-padding-y: 0.775rem !important;
    --bs-btn-font-size: 1.1rem !important;
    --bs-btn-font-weight: 500 !important;
    --bs-btn-line-height: 1.5 !important;
    --bs-btn-color: #181C32 !important;
    --bs-btn-bg: transparent !important;
    --bs-btn-border-width: 1px !important;
    --bs-btn-border-color: transparent !important;
    --bs-btn-border-radius: 0.475rem !important;
    --bs-btn-hover-border-color: transparent !important;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    --bs-btn-disabled-opacity: 0.65 !important;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5) !important;
    display: inline-block !important;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
    font-family: var(--bs-btn-font-family) !important;
    font-size: var(--bs-btn-font-size) !important;
    font-weight: var(--bs-btn-font-weight) !important;
    line-height: var(--bs-btn-line-height) !important;
    color: var(--bs-btn-color) !important;
    text-align: center !important;
    vertical-align: middle !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color) !important;
    border-radius: var(--bs-btn-border-radius) !important;
    background-color: var(--bs-btn-bg) !important;
    box-shadow: var(--bs-btn-box-shadow) !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  --bs-btn-color: var(--kt-body-color) !important;
    --bs-btn-bg: transparent !important;
    --bs-btn-border-color: transparent !important;
    outline: none !important;
    --bs-btn-padding-y: 0.55rem !important;
    --bs-btn-padding-x: 1.25rem !important;
    --bs-btn-font-size: 0.925rem !important;
    --bs-btn-border-radius: 0.425rem !important;
  background-color: #144067 !important;
  color: #ffffff !important;
  margin-left: 10px !important;
  font-weight: 600 !important;
  border: 1px solid var(--kt-input-border-color);
}

td.fc-timegrid-slot.fc-timegrid-slot-lane {
  display: none;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame{
  text-align: left;
  clear: both;
}